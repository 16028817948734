<template>
  <div class="trainingWrap container">
    <header>
      <headerTop></headerTop>
      <navTab></navTab>
    </header>
    <div class="main-content">
        <div class="title">
          精品课程 > {{courseTitle}}
        </div>
        <emptyData v-show='recordsData.length==0'></emptyData>
        <div class="jpkc-content-main"  v-show='recordsData.length>0'>
            <ul class="list-ul">
              <li v-for="(item,index) in recordsData" :key='index' @click='jumpCourseInfo(item.id)'>
                <div class="course-cover" v-show='!item.chapterCoverUrl'>
                    <div class="course-content">
                      {{item.chapterTitle}}
                    </div>
                  </div>
                  <div class="course-cover" v-show='item.chapterCoverUrl'>
                    <img :src="item.chapterCoverUrl" alt="">
                  </div>
                <div class="course-title">{{item.chapterTitle}}</div>
              </li>
            </ul>
        </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import emptyData from '@/components/emptyData.vue'
import navTab from '@/components/navTab.vue'
import headerTop from '@/components/header.vue'
import footerCom from '@/components/footer.vue'
export default {
  name: '',
  components: {
    emptyData,
    footerCom,
    navTab,
    headerTop
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      recordsData: [],
      courseTitle: '',
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    },
    jumpCourseInfo (courseId) {
      this.$router.push(`/overChildrenCourseInfo/${courseId}`)
    },
    // 获取课程详情
    getCourseDetail () {
      console.log('mounted', this.$route.params.courseId)
      this.$axios.get(this.$apiUrl.loadCourseDetail, {params: {courseId: this.$route.params.courseId}}).then(res => {
        console.log(res)
        let resData = res.data.data
        let {chapterList, courseTitle} = resData
        this.courseTitle = courseTitle // 课程标题
        this.recordsData = chapterList // 获取课程列表
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
  },
  mounted () {
    this.getUserInfo()
    this.getCourseDetail()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;
  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }
}
  .main-content {
    width: 1240px;
    min-height: 400px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 30px;
    padding-bottom: 188px;
    background-color: transparent;
    .title{
      font-size: 24px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #1C4ECC;
      line-height: 50px;
    }
  .jpkc-content-main {
    padding-top: 30px;
    .list-ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 33.3%;
        color: #000000;
        margin-bottom: 50px;
        .course-cover {
          cursor: pointer;
          width: 385px;
          height: 256px;
          font-size: 30px;
          font-family: STKaiti-Regular, STKaiti;
          img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
          .course-content {
            background: url("../../assets/tuanBg.png") no-repeat;
            background-size: 100% 100%;
            width: 385px;
            height: 256px;
            border-radius: 10px;
            display: flex;
            flex-wrap: wrap;
            padding: 0px 10px;
            justify-content: center;
            align-content: center;
            color: #ffff;
          }
        }
        .course-title {
          cursor: pointer;
          padding-top: 22px;
          width: 384px;
          font-size: 22px;
          line-height: 33px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          color: #000000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2 ;
          -webkit-box-orient: vertical;
        }
        .course-title:hover{
            color: #25A3FF ;
          }
      }
    }
  }
  }
</style>
